@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap');



body {
  margin: 0;
  font-family: 'Merriweather', sans-serif; /* Or 'Montserrat', sans-serif; */
  /* font-family: 'Merriweather', sans-serif;  */
  background-color: white; /* Bright background */
  /*color: #333; /* Standard text color for readability */
  color: #2A2928;
}

a {
  color: #007BFF; /* Primary color for links */
  text-decoration: none; /* Removes underline from links */
}

a:hover {
  color: #0056b3; /* Darker shade of primary color for hover effect */
}

.button {
  background-color: #FFC107; /* Secondary color for buttons */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #e0a800; 
}

.header {
  background-color: #007BFF; 
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

html {
  scroll-behavior: smooth;
}