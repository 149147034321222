.experience-projects {
  background-color: #a2d2ff;
  color: #221e22;
  padding-top: 6rem; 
  padding-bottom: 6rem;
  padding-right: 4rem;
  padding-left: 4rem;
  border-bottom: 1px solid #221e22;
}

.navigation-bar {
  display: flex;
  justify-content: center; 
  flex-wrap: nowrap; 
  overflow-x: auto; 
  gap: 5px; 
  margin: 0 auto; 
  list-style: none; 
  padding-bottom: 5rem;
}

.nav-item {
  flex-grow: 1;
  text-align: center; 
  padding: 15px; 
  font-size: 1.2rem; 
  background-color: white; 
  border: 1.5px solid #221e22; 
  white-space: nowrap; 
  width: 25%;
}

.nav-item.active, .nav-item:hover {
  background-color: #221e22;
  color: white;
  font-weight: bold; 
}

.content-area {
  display: flex;
  align-items: flex-start; 
}

.photo-frame {
  width: 50%; 
  display: flex;
  justify-content: center; 
  align-items: center;
  max-width: 700px; 
  height: 400px; 

  
}

.photo-frame img {
  height: auto;
  width: 100%;
  border: 1.5px solid #221e22;

}

.description {
  width: 50%; 
  padding-left: 3rem; 

}

.description h2 {
  margin-bottom: 1rem;
  text-align: center;
}

.description p {
  font-size: 1rem;
  line-height: 1.6;
}
